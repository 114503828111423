import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import bgShape1 from "@images/banner/banner-shape-1-1.png";
import bgShape2 from "@images/banner/banner-shape-1-2.png";
import bannerBG from "@images/banner/banner-bg-1.png";
import bannerShape1 from "@images/shapes/banner-1-1.png";
import bannerShape2 from "@images/banner/banner-shape-1-4.png";
import image from "@images/banner/banner-img-1.png";

const ConseilIntro = () => {
  return (
    <section className="banner-one" id="conseil">
      <img src={bgShape1} alt="Banner-Shape-1" className="banner-shape-1" />
      <div
        className="banner-one__bg"
        style={{ backgroundImage: `url(${bannerBG})` }}
      ></div>
      <Container>
        <Row>
          <Col xl={8} lg={8}>
            <div className="banner-one__content">
              <h3>
                Consulting, <br />design <br />and integration
              </h3>
              <p>
              Generative AIs, particularly language models, have already begun to revolutionize many sectors of activity.
              <br />All organizations can seize the opportunities and potential offered by these technologies to improve their operations and business model.
              <br />And all must prioritize defining the framework for using generative AIs in their activities.</p>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default ConseilIntro;
