import React from "react";
import Layout from "@components/layout";
import Header from "@components/header/header";

import ConseilIntro from "@components/conseil-intro";
import ConseilStrat from "@components/conseil-strat";
import ConseilPrompt from "@components/conseil-prompt";
import ConseilSolution from "@components/conseil-solution";
import Contact from "@components/contact";
import Footer from "@components/footer";
import MobileNav from "@components/mobile-nav";


const Conseil = () => {
  return (
    <Layout pageTitle="Conseil || Prompt Breeders">
      <Header />
      <ConseilIntro />
      <ConseilStrat />
      <ConseilPrompt />
      <ConseilSolution />
      <Contact />
      <Footer />
      <MobileNav />
    </Layout>
  );
};

export default Conseil;
